h5 {
  color: var(--SecondaryColor);
  margin: 30px 0;
  text-align: center;
  font-size: 1rem;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: 20px 0;
}

.map-responsive iframe {
  left: 0;
  top: 0;
  border: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}/*# sourceMappingURL=map.css.map */